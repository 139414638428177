<template>
  <div id="bg">
    <div id="index" ref="appRef">
      <div>
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else class="host-body">
          <div class="d-flex jc-center">
            <div style="position: absolute;left: 10px;font-size: 24px">
              <el-button type="text" style="font-size: 18px;color: white" @click="goback">返回工作台</el-button>
              <el-button v-if="srceen" type="text" style="font-size: 18px;color: white" @click="gotoPage">前往当日大屏</el-button>
              <el-button v-else type="text" style="font-size: 18px;color: white" @click="gotoPage">前往月度大屏</el-button>
            </div>
            <dv-decoration-10 class="dv-dec-10"></dv-decoration-10>
            <div class="d-flex jc-center">
              <dv-decoration-8 class="dv-dec-8" :color="['#568aea']"></dv-decoration-8>
              <div class="title">
                <span class="title-text">{{title}}</span>
                <dv-decoration-6
                  class="dv-dec-6"
                  :reverse="true"
                  :color="['#50e3c2', '#67a1e5']"
                ></dv-decoration-6>
              </div>
              <dv-decoration-8
                class="dv-dec-8"
                :reverse="true"
                :color="['#568aea']"
              ></dv-decoration-8>
            </div>
            <div class="react-right mr-4 react-l-s" style="position: absolute;right: 0px;">
              <span class="react-after"></span>
              <span
                class="text"
              >{{dateYear}} {{dateWeek}} {{dateDay}}</span
              >
            </div>
            <dv-decoration-10 class="dv-dec-10-s"></dv-decoration-10>
          </div>

          <!-- 第二行 -->
          <div style="height: 40px">
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import drawMixin from "../../util/drawMixin";
  import { formatTime } from "@/util";
  export default {
    components: {
    },
    mixins: [ drawMixin ],
    props:{
      title:{
        type:String,
        default:"梯智物联监控中心",
      },
    },
    data() {
      return {
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        srceen:false,
      };
    },

    mounted() {
      console.log(this.$route.path,152);
      if (this.$route.path.indexOf("2")>-1) {
        this.srceen=true;
      }
      this.timeFn();
      this.cancelLoading();
    },
    beforeDestroy() {
      clearInterval(this.timing);
    },
    methods: {
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), "HH: mm: ss");
          this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
          this.dateWeek = this.weekday[new Date().getDay()];
        }, 1000);
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      },
      goback() {
        this.$router.push("/");
      },
      gotoPage() {
        if (this.srceen) {
          this.$router.push("/bigscreen/index");

        }else {
          this.$router.push("/bigscreen/index2");
        }
      },
      goTemplateOne() {
        this.$router.push("/template/index");
      },
      goTemplateSecond() {
        this.$router.push("/templateSecond/index");
      },
    },
  };
</script>

<style lang="scss">

@import '../../assets/scss/index.scss';
</style>
